import { addPropertyControls, ControlType } from "framer"
import React, { useEffect, useState } from "react"

const Ticker = ({
    fontFamily,
    iconBorderRadius,
    iconSize,
    publisherFontSize,
    publisherFontWeight,
    publisherColor,
    priceFontSize,
    priceFontWeight,
    priceColor,
}) => {
    const dummyData = [
        {
            name: "VÍSIR.IS",
            price: 539,
            icon: "https://via.placeholder.com/20?text=V",
        },
        {
            name: "JÁ.IS",
            price: 624,
            icon: "https://via.placeholder.com/20?text=J",
        },
        {
            name: "FÓTBOLTI.NET",
            price: 491,
            icon: "https://via.placeholder.com/20?text=F",
        },
        {
            name: "KELDAN",
            price: 782,
            icon: "https://via.placeholder.com/20?text=K",
        },
        {
            name: "Blika.is",
            price: 500,
            icon: "https://via.placeholder.com/20?text=B",
        },
    ]

    const [data, setData] = useState([])

    //   useEffect(() => {
    //       // Simulate API call
    //       setTimeout(() => {
    //           setData(dummyData)
    //       }, 1000)
    //   }, [])

    // Fetch data from the real API

    useEffect(() => {
        fetch("https://storage.googleapis.com/puls/other/bm/bm-hc.json")
            .then((response) => response.json())
            .then((apiData) => setData(apiData))
            .catch((error) => console.error("Error fetching data:", error))
    }, [])

    return (
        <div
            style={{
                display: "flex",
                overflow: "hidden",
                whiteSpace: "nowrap",
            }}
        >
            {data.map((item, index) => (
                <div
                    key={index}
                    style={{
                        padding: "0 20px",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <img
                        src={item.icon}
                        alt={`${item.name} icon`}
                        style={{
                            marginRight: "6px",
                            borderRadius: `${iconBorderRadius}px`,
                            height: `${iconSize}px`,
                            width: `${iconSize}px`,
                        }}
                    />
                    <span
                        style={{
                            marginRight: "7px",
                            fontFamily: fontFamily,
                            fontSize: `${publisherFontSize}px`,
                            fontWeight: publisherFontWeight,
                            color: publisherColor,
                        }}
                    >
                        {item.name}
                    </span>
                    <span
                        style={{
                            fontFamily: fontFamily,
                            fontSize: `${priceFontSize}px`,
                            fontWeight: priceFontWeight,
                            color: priceColor,
                        }}
                    >
                        {item.price} kr.
                    </span>
                </div>
            ))}
        </div>
    )
}

// Wrapping the component for Framer
export function TickerComponent(props) {
    return <Ticker {...props} />
}

// Add property controls
addPropertyControls(TickerComponent, {
    fontFamily: {
        type: ControlType.String,
        title: "Font Family",
        defaultValue: "Open Sans",
    },
    iconBorderRadius: {
        type: ControlType.Number,
        title: "Icon Border Radius",
        defaultValue: 4,
        min: 0,
        max: 50,
    },
    iconSize: {
        type: ControlType.Number,
        title: "Icon Size",
        defaultValue: 40,
        min: 0,
        max: 100,
    },
    publisherFontSize: {
        type: ControlType.Number,
        title: "Publisher Font Size",
        defaultValue: 14,
        min: 10,
        max: 30,
    },
    publisherFontWeight: {
        type: ControlType.Number,
        title: "Publisher Font Weight",
        defaultValue: 400,
        min: 100,
        max: 900,
        step: 100,
    },
    publisherColor: {
        type: ControlType.Color,
        title: "Publisher Color",
        defaultValue: "#000000",
    },
    priceFontSize: {
        type: ControlType.Number,
        title: "Price Font Size",
        defaultValue: 14,
        min: 10,
        max: 30,
    },
    priceFontWeight: {
        type: ControlType.Number,
        title: "Price Font Weight",
        defaultValue: 400,
        min: 100,
        max: 900,
        step: 100,
    },
    priceColor: {
        type: ControlType.Color,
        title: "Price Color",
        defaultValue: "#000000",
    },
})

TickerComponent.defaultProps = {
    height: 50,
    width: 300,
    fontFamily: "Open Sans",
    iconBorderRadius: 4,
    iconSize: 20,
    publisherFontSize: 14,
    publisherFontWeight: 400,
    publisherColor: "#000000",
    priceFontSize: 14,
    priceFontWeight: 400,
    priceColor: "#000000",
}
